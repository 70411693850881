import { useEffect } from 'react';
import { toast, ToastOptions } from 'react-toastify';

type ToastType = 'info' | 'error' | 'success';

const useToastWrapper = () => {
  const showToast = (message: string, type: ToastType = 'info', options?: ToastOptions) => {
    switch (type) {
      case 'info':
        toast.info(message, options);
        break;
      case 'error':
        toast.error(message, options);
        break;
      case 'success':
        toast.success(message, options);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    return () => {
      toast.dismiss();
    };
  }, []);

  return { showToast };
};

export default useToastWrapper;

import { MdOutlineEmail } from "react-icons/md";
import { IoArrowBack } from "react-icons/io5";
import { MdOutlineLocalPhone } from "react-icons/md";
import { IoLogoWhatsapp } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import { handleIsUploadScreen, handleRoute } from "../store/user/user.slice";
import { useNavigate } from "react-router-dom";



function Navbar() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const user = useSelector((state: RootState) => state.user)
    const partnerLogo = user?.partnerDetails?.partnerLogo

    const backHandler = () => {
        if (window.location.pathname === '/loading') {
            navigate('/policy')
            return
        }
        if (window.location.pathname === '/policy') {
            navigate('/')
            return
        }
        if (window.location.pathname === '/policy-details') {
            navigate('/policy')
            return
        }
        if (window.location.pathname === '/result') {
            navigate('/')
            return
        }
        if (window.location.pathname === '/tryAgain') {
            navigate('/')
            return
        }
        navigate(-1);
    }

    return (
        <nav className="bg-white shadow-navbar">
            <div className="lg:flex lg:justify-start flex justify-between items-center px-1 pr-4 py-3">
                <div className='flex justify-start items-center gap-2 pl-2'>
                    {window.location.pathname !== '/' && <IoArrowBack onClick={backHandler} fontSize={'20px'} />}
                   {partnerLogo && <img src={partnerLogo} alt="logo" className="h-8 w-8" />}
                </div>
                <div className="ml-7 my-2 lg:w-50">
                    <img src="/assets/insa.png" alt="logo" className="h-8 w-40" />
                </div>
                <div className="flex items-center ml-20 lg:flex hidden"> {/* Use ml-auto to push items to the right */}
                    <div className='flex items-center'>
                        <MdOutlineEmail size={"22px"} /><span className='pl-1'>corporate@insurancesamadhan.com</span>
                    </div>
                    <div className='flex items-center ml-3'>
                        <MdOutlineLocalPhone size={"22px"} />
                        <span className='ml-1'>95136-31312</span>
                    </div>
                    <div className='flex items-center ml-3'>
                        <IoLogoWhatsapp size={"22px"} />
                        <span className='ml-1'>99109-98252</span>
                    </div>
                </div>
            </div>
        </nav>
    )
};

export default Navbar
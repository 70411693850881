import { useEffect, useState } from "react"
import ContainerOne from "../../component/common/ContainerOne"
import Heading1 from "../../component/semantic/Heading1"
import FormInput from "../../component/semantic/InputOne"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../store/store"
import { getCompanyList, couponCheck, getLifeQusetions, healthQualityResult, kypTransaction, lifeQualityCheck, gethealthQualityDetails } from "../../services/request"
import { handleDraftDetails, handleRoute } from "../../store/user/user.slice"
import CouponField from "./CouponField"
import { ErrorMessage, Field, Formik } from "formik"
import { useNavigate } from "react-router-dom"
import { healthValidation, lifeValidation } from "./validation"
import { formatDate } from "../../helpers/Utils"

const getHeading = (headingType = '') => {
    switch (headingType) {
        case 'Basic':
            return 'Basic'
        case 'Health&lifestyle':
            return 'Health & Lifestyle'
        case 'Financial&occupation':
            return 'Financial & Occupation'
        case 'ContactDetails&admin':
            return 'Contact & Admin'
        default:
            return 'Basic'
    }
}

const Questionare = () => {
    const { policy, relations } = useSelector((state: RootState) => state.user)
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [companyList, setCompanyList] = useState([]);
    const [questions, setQuestions] = useState({})
    const [loading, setLoading] = useState(true);
    const [isHealthScreen, setIsHealthScreen] = useState(false)
    const [questionDetails, setQuestionDetails] = useState<any>({});

    const fetchHandler = async () => {
        setLoading(true)
        let resp = null
        if (policy?.policyTypeId?._id === '864a375b-5fa7-49c7-9f39-2b1fda4674b5') {
            resp = await getLifeQusetions({ policyId: policy?._id })
            setQuestions(resp?.data?.Questions)
            setQuestionDetails(resp?.data?.QuestionValues)
        } else {
            setIsHealthScreen(true)
            resp = await gethealthQualityDetails(policy?._id)
            setQuestions(resp?.data?.Questions)
            setQuestionDetails(resp?.data?.docEyeOutput)
        }
        setLoading(false)
    }

    const fetchCompanies = async () => {
        const data = await getCompanyList("864a375b-5fa7-49c7-9f39-2b1fda4674b5")
        setCompanyList(data.data?.map((res: any) => { return { label: res.name, value: res._id } }));
    }

    useEffect(() => {
        fetchHandler()
        fetchCompanies()
    }, [])

    const [redeemColor, setRedeemColor] = useState<any>({ textColor: "#0000004D", borderColor: "#FAAA52", inputBackground: "", redeemSuccess: false })
    const [redeemValue, setRedeemValue] = useState("");
    const [isKYPFree, setIsKYPFree] = useState(false);

    const couponHandler = async (values: any) => {
        if (isKYPFree) {
            let checkResult:any = { success: false };
            if (policy?.policyTypeId?._id === "864a375b-5fa7-49c7-9f39-2b1fda4674b5") {
                checkResult = await lifeQualityCheck({ changeQuestionValues: values, policyId: policy?._id });
                dispatch(handleDraftDetails(checkResult?.data?.Draft))
                navigate('/result');
                // dispatch(handleRoute(5));
            } else {
                checkResult = await healthQualityResult({ userInput: values, advanced: true, policyId: policy?._id });
                dispatch(handleDraftDetails(checkResult?.data?.Draft))
                navigate('/result');
                // dispatch(handleRoute(5));
            }
            if (checkResult?.success) {
                navigate('/result');
                dispatch(handleDraftDetails(checkResult?.data?.Draft))
                // dispatch(handleRoute(5));
            }
            return;
        }
        try {
            const responseData = await couponCheck(redeemValue);
            if (responseData?.success) {
                setIsKYPFree(true)
                setRedeemColor({ ...redeemColor, textColor: "", borderColor: "#85CEBC", inputBackground: "!bg-[#eaf5f2] font-semibold font-[#000000] border-b-[#2D9E7F]", redeemSuccess: true, couponValue: responseData?.data?.data })
            } else {
                setRedeemColor({ ...redeemColor, textColor: "#0000004D", borderColor: "#85CEBC", inputBackground: "!bg-[#ffeef2] font-semibold font-[#000000] border-b-[#fb83a1]" })
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (redeemValue) {
            setRedeemColor({ ...redeemColor, textColor: "#FAAA52", borderColor: "#FAAA52", inputBackground: "!bg-[#fef7ee] font-semibold font-[#000000] border-b-[#FAAA52]" })
        } else {
            setRedeemColor({ ...redeemColor, textColor: "#0000004D", inputBackground: "" })
        }
    }, [redeemValue]);

    async function displayRazorpay(data: any, values: any) {
        const options: any = {
            key: process.env.REACT_APP_RAZORPAY_KEY,
            amount: data?.amount || 50000,
            name: "Insurance Samadhan",
            description:
                "Pay for Policies " + policy._id,
            image: "image",
            prefill: {
                email: data.email,
                contact: data.phone,
            },
            notes: [data.txnId],
            theme: {
                color: "#000000",
            },
            handler: async function (response: any) {
                try {
                    let checkResult:any = { success: false };
                    if (policy?.policyTypeId?._id === "864a375b-5fa7-49c7-9f39-2b1fda4674b5") {
                        checkResult = await lifeQualityCheck({ changeQuestionValues: values, policyId: policy?._id })
                    } else {
                        checkResult = await healthQualityResult({ userInput: values, advanced: true, policyId: policy?._id })
                    }
                    if (checkResult?.success) {
                        dispatch(handleDraftDetails(checkResult?.data?.Draft))
                        // dispatch(handleRoute(6));
                        navigate('/result');
                    }
                    //   const result = await makeRequest("POST", "/transaction/initiate/" + data.txnId, data)
                    // const result = await axios.request(
                    // baseUrl +  + data.txnId,
                    // {
                    //   method: "POST",
                    //   headers: {
                    //     Authorization: activeUser?.token,
                    //   },
                    // }
                    // data
                    // );
                    console.log("Payment Successfull");
                } catch (error) {
                    console.log("Something went wrong");
                }
            },
        };
        const paymentObject: any = new (window as any).Razorpay(options);
        paymentObject.open();
    }

    const handlePayment = async (values: any) => {
        let payload: { policyId: string, coupon?: string } = {
            policyId: policy._id
        }
        if (redeemColor.redeemSuccess) {
            payload['coupon'] = redeemColor.couponValue
        }
        const payment = await kypTransaction(payload);
        if (payment?.data?.payStatus) {
            displayRazorpay(payment?.data || {}, values);
            return
        }
        //Navigate
        return;
    }

    if (loading) return <div></div>

    return <><div className="ml-2 py-2 mx-2  mb-[100px]">
        <Heading1 className="font-extrabold text-sm">Know Your Policy</Heading1>
        <ContainerOne className='!bg-[unset] border border-[#D8DDE9] mt-2 !p-0 !gap-0 !pt-[4px]'>
            <Heading1 className="text-xs p-2 shadow-bottom pb-4 m px-4 py-4">To generate the result, please respond to the following questions</Heading1>
            {
                !isHealthScreen
                    ? <Formik validationSchema={lifeValidation} initialValues={questionDetails} onSubmit={(values, { setSubmitting }) => {}}>
                        {({
                            values,
                            errors,
                            touched,
                            setValues,
                            handleSubmit
                        }) => {
                            return <form onSubmit={handleSubmit}>
                                {Object.keys(questions).filter(i => i !== 'Basic').map((ques: string) => {
                                    return <div className="my-2">
                                        <Heading1 className="font-extrabold text-sm px-4">{getHeading(ques)}</Heading1>
                                        {
                                            (questions as any)[ques].map((i: any) => {
                                                return <div className="shadow-bottom py-4 px-4">
                                                    <div className="flex justify-between items-center gap-4">
                                                        <Heading1 className="text-xs font-semibold">{i.Question}</Heading1>
                                                        {
                                                            (i.QuestionType === 'Switch') && <div className="">
                                                                <div className="flex items-center relative">
                                                                    <Field onChange={(e: any) => {
                                                                        setValues((prevValues: any) => ({
                                                                            ...prevValues,
                                                                            [e.target.name]: e.target.value === "true"
                                                                        }))
                                                                    }
                                                                    } name={i.Id} value={true} type="radio" className="w-[12px] h-[12px] text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                    <label htmlFor={`${i.Id}`} className="ms-2 text-xs font-medium text-gray-900 ml-4">Yes</label>
                                                                </div>
                                                                <div className="flex items-center relative">
                                                                    <Field onChange={(e: any) => {
                                                                        setValues((prevValues: any) => ({
                                                                            ...prevValues,
                                                                            [e.target.name]: e.target.value === "true"
                                                                        }))
                                                                    }
                                                                    } name={i.Id} value={false} type="radio" className="w-[12px] h-[12px] text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                    <label htmlFor={`${i.Id}`} className="ms-2 text-xs font-medium text-gray-900 ml-4">No</label>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="mt-2 mb-2">
                                                        {
                                                            ((!!values[i.Id] && i.ShowExtrasOnYes) || (!values[i.Id] && !i.ShowExtrasOnYes)) && (i?.SubQuestion || [] as any).map((j: any) => {
                                                                return <div className={`flex justify-between gap-4 ${j.QuestionType === 'MultiSelect' ? 'grid grid-col-2' : j.QuestionType === 'Custom' || j.QuestionType === 'Custom_Multiple' ? 'flex flex-col' : ''}`}>
                                                                    <Heading1 className="text-xs">{j.Question}</Heading1>
                                                                    {
                                                                        (j.QuestionType === 'Switch') && <div className="">
                                                                            <div className="flex items-center relative">
                                                                                <Field onChange={(e: any) => {
                                                                                    setValues((prevValues: any) => ({
                                                                                        ...prevValues,
                                                                                        [e.target.name]: e.target.value === "true"
                                                                                    }))
                                                                                }
                                                                                } name={j.Id} value={true} type="radio" className="w-[12px] h-[12px] text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                                <label htmlFor={`${j.Id}`} className="ms-2 text-xs font-medium text-gray-900 ml-4">Yes</label>
                                                                            </div>
                                                                            <div className="flex items-center relative">
                                                                                <Field onChange={(e: any) => {
                                                                                    setValues((prevValues: any) => ({
                                                                                        ...prevValues,
                                                                                        [e.target.name]: e.target.value === "true"
                                                                                    }))
                                                                                }
                                                                                } name={j.Id} value={false} type="radio" className="w-[12px] h-[12px] text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                                <label htmlFor={`${j.Id}`} className="ms-2 text-xs font-medium text-gray-900 ml-4">No</label>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {(j.QuestionType === 'MultiSelect') && <div className="grid grid-cols-2">
                                                                        {
                                                                            (j?.Value || []).map((det: any) => {
                                                                                return <div key={det.Id} className="flex items-center mb-2">
                                                                                    <Field type="checkbox" name={det.Id} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                                    <label htmlFor={det.Id} className="ms-2 text-xs">{det.Option}</label>
                                                                                </div>
                                                                            })
                                                                        }
                                                                    </div>
                                                                    }
                                                                    {['Custom'].includes(j.QuestionType) && <div className="w-full">
                                                                        {
                                                                            (j?.Fields || []).map((det: any) => {
                                                                                return <div className="flex flex-col items-start mb-2">
                                                                                    <Heading1 className="text-xs font-normal mb-1">{det.Name}</Heading1>
                                                                                    {
                                                                                        det.Type === 'Text' && <>
                                                                                        <Field
                                                                                            key={`${j.Id}_${det.Id}`}
                                                                                            placeholder={det.Name}
                                                                                            type={'text'}
                                                                                            className='text-sm p-2 !w-full border rounded-[8px]'
                                                                                            // value={questionDetails[j.Id][det.Id]}
                                                                                            name={`${j.Id}.${det.Id}`}
                                                                                        />
                                                                                        <div className="text-xs font-semibold !text-[red]"><ErrorMessage name={`${j.Id}.${det.Id}`} /></div>
                                                                                        </>
                                                                                        
                                                                                    }
                                                                                    {
                                                                                        det.Type === 'NumericText' &&<> <Field
                                                                                            key={`${j.Id}_${det.Id}`}
                                                                                            placeholder={det.Name}
                                                                                            type={'number'}
                                                                                            className='text-sm p-2 !w-full border rounded-[8px]'
                                                                                            // value={questionDetails[j.Id][det.Id]}
                                                                                            name={`${j.Id}.${det.Id}`}
                                                                                        />
                                                                                         <div className="text-xs font-semibold !text-[red]"><ErrorMessage name={`${j.Id}.${det.Id}`} /></div>
                                                                                        </>
                                                                                    }
                                                                                    {
                                                                                        det.Type === 'Dropdown' && det.Name === 'Insurance Company Name' && <>
                                                                                        <Field
                                                                                            key={`${j.Id}_${det.Id}`}
                                                                                            placeholder={det.Name}
                                                                                            as="select"
                                                                                            className='text-sm p-2 !w-full border rounded-[8px]'
                                                                                            // value={questionDetails[j.Id][det.Id]}
                                                                                            name={`${j.Id}.${det.Id}`}
                                                                                        >
                                                                                            <option value={''}>Insurance Company Name</option>
                                                                                            {
                                                                                                companyList.map((company: any) => {
                                                                                                    return <option value={company.id}>{company.label}</option>
                                                                                                })
                                                                                            }
                                                                                        </Field>
                                                                                        <div className="text-xs font-semibold !text-[red]"><ErrorMessage name={`${j.Id}.${det.Id}`} /></div>
                                                                                    
                                                                                        </>
                                                                                    }
                                                                                    {
                                                                                        det.Type === 'Dropdown' && det.Id === 'Relationship' && <>
                                                                                        <Field
                                                                                            key={`${j.Id}_${det.Id}`}
                                                                                            placeholder={det.Name}
                                                                                            as="select"
                                                                                            className='text-sm p-2 !w-full border rounded-[8px]'
                                                                                            // value={questionDetails[j.Id][det.Id]}
                                                                                            name={`${j.Id}.${det.Id}`}
                                                                                        >
                                                                                            <option value={''}>Relation</option>
                                                                                            {
                                                                                                relations.map((company: any) => {
                                                                                                    return <option value={company.name}>{company.name}</option>
                                                                                                })
                                                                                            }
                                                                                        </Field>
                                                                                        <div className="text-xs font-semibold !text-[red]"><ErrorMessage name={`${j.Id}.${det.Id}`} /></div>
                                                                                    
                                                                                        </>
                                                                                    }
                                                                                </div>
                                                                            })
                                                                        }
                                                                    </div>
                                                                    }
                                                                    {['Custom_Multiple'].includes(j.QuestionType) && <div className="w-full">
                                                                        {
                                                                            (j?.Fields || []).map((det: any, index: number) => {
                                                                                return <div className="flex flex-col items-start mb-2">
                                                                                    <Heading1 className="text-xs font-normal mb-1">{det.Name}</Heading1>
                                                                                    {
                                                                                        det.Type === 'Text' && <Field
                                                                                            key={`${j.Id}_${det.Id}`}
                                                                                            placeholder={det.Name}
                                                                                            type={'text'}
                                                                                            className='text-sm p-2 !w-full border rounded-[8px]'
                                                                                            // value={questionDetails[j.Id][det.Id]}
                                                                                            name={`${j.Id}.${index}.${det.Id}`}
                                                                                        />
                                                                                    }
                                                                                    {
                                                                                        det.Type === 'NumericText' && <Field
                                                                                            key={`${j.Id}_${det.Id}`}
                                                                                            placeholder={det.Name}
                                                                                            type={'number'}
                                                                                            className='text-sm p-2 !w-full border rounded-[8px]'
                                                                                            // value={questionDetails[j.Id][det.Id]}
                                                                                            name={`${j.Id}.${index}.${det.Id}`}
                                                                                        />
                                                                                    }
                                                                                    {
                                                                                        det.Type === 'Dropdown' && det.Name === 'Insurance Company Name' && <Field
                                                                                            key={`${j.Id}_${det.Id}`}
                                                                                            placeholder={det.Name}
                                                                                            as="select"
                                                                                            className='text-sm p-2 !w-full border rounded-[8px]'
                                                                                            // value={questionDetails[j.Id][det.Id]}
                                                                                            name={`${j.Id}.${index}.${det.Id}`}
                                                                                        >
                                                                                            <option value={''}>Insurance Company Name</option>
                                                                                            {
                                                                                                companyList.map((company: any) => {
                                                                                                    return <option value={company.id}>{company.label}</option>
                                                                                                })
                                                                                            }
                                                                                        </Field>
                                                                                    }
                                                                                    {
                                                                                        det.Type === 'Dropdown' && det.Id === 'Relationship' && <>
                                                                                        <Field
                                                                                            key={`${j.Id}_${det.Id}`}
                                                                                            placeholder={det.Name}
                                                                                            as="select"
                                                                                            className='text-sm p-2 !w-full border rounded-[8px]'
                                                                                            // value={questionDetails[j.Id][det.Id]}
                                                                                            name={`${j.Id}.${index}.${det.Id}`}
                                                                                        >
                                                                                            <option value={''}>Relation</option>
                                                                                            {
                                                                                                relations.map((company: any) => {
                                                                                                    return <option value={company.name}>{company.name}</option>
                                                                                                })
                                                                                            }
                                                                                        </Field>
                                                                                        <div className="text-xs font-semibold !text-[red]"><ErrorMessage name={`${j.Id}.${det.Id}`} /></div>
                                                                                    
                                                                                        </>
                                                                                    }
                                                                                </div>
                                                                            })
                                                                        }
                                                                    </div>
                                                                    }
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            })}
                                    </div> 
                                })}
                                <nav className="fixed bottom-0 right-0 left-0 bg-white  rounded-t-lg lg:pt-4">
                                    <CouponField touched={touched} errors={errors} redeemValue={redeemValue} redeemColor={redeemColor} couponHandler={() => couponHandler(values)} setRedeemValue={setRedeemValue} isKYPFree={isKYPFree} handlePayment={() => handlePayment(values)} />
                                </nav>
                            </form>

                        }}
                    </Formik>
                    : <Formik validationSchema={healthValidation} initialValues={questionDetails} onSubmit={(values, { isValid }: any) => {}}>
                        {({
                            values,
                            errors,
                            touched,
                            setValues,
                            handleSubmit
                        }) => {
                                                        return <form className="" onSubmit={handleSubmit}>
                                <div className="mb-4 shadow-bottom px-4 py-4">
                                    <div className="flex justify-between items-start">
                                        <div>
                                            <Heading1 className="font-bold text-xs">1) Policy Holder Name</Heading1>
                                            <Heading1 className="font-light text-xs mt-1">&nbsp;&nbsp;&nbsp;&nbsp;{questionDetails.policyHolderName}</Heading1>
                                        </div>
                                        <div>
                                            <div className="flex items-center relative">
                                                <Field onChange={(e: any) => {
                                                    setValues((prevValues: any) => ({
                                                        ...prevValues,
                                                        [e.target.name]: e.target.value === "true",
                                                        policyHolderName: questionDetails?.policyHolderName
                                                    }))
                                                }
                                                } name={'isCorrectName'} value={true} type="radio" className="w-[12px] h-[12px] text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                <label htmlFor={`${'isCorrectName'}`} className="ms-2 text-xs font-medium text-gray-900 ml-4">Yes</label>
                                            </div>
                                            <div className="flex items-center relative">
                                                <Field onChange={(e: any) => {
                                                    setValues((prevValues: any) => ({
                                                        ...prevValues,
                                                        [e.target.name]: e.target.value === "true"
                                                    }))
                                                }
                                                } name={'isCorrectName'} value={false} type="radio" className="w-[12px] h-[12px] text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                <label htmlFor={`${'isCorrectName'}`} className="ms-2 text-xs font-medium text-gray-900 ml-4">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    {!values?.isCorrectName && <div className="mt-4">
                                        <Heading1 className="text-xs font-normal mb-1">Please enter the correct name</Heading1>
                                        <Field value={values.policyHolderName} className='text-sm p-2 !w-full border rounded-[8px]' name={'policyHolderName'} />
                                        <div className="text-xs font-semibold !text-[red]"><ErrorMessage name="policyHolderName" /></div>
                                    </div>}
                                </div>
                                <div className="mb-4 shadow-bottom px-4 py-4">
                                    <Heading1 className="font-bold text-xs">2) Insured Details</Heading1>
                                    {
                                        (questionDetails?.insuredDetails || []).map((insuredDetail: any, index: number) => {
                                            return <div className="w-full">
                                                <div className="relative mt-4">
                                                <div className="absolute left-[0px] top-[-7px] bg-[#9DB4E5] p-2 w-[10px] h-[10px] flex justify-center items-center font-bold text-xs text-white rounded-[4px]">{index + 1}</div>
                                                    <div className="border-t border-dashed border-[#AAB8D7]"></div>
                                                </div>
                                                <div className="w-full mt-4 flex justify-between items-start">
                                                    <div className="w-full">
                                                        <div className="w-full mb-3">
                                                        {!values.insuredDetails[index].isCorrectInsuredDetails && <Heading1 className="font-bold text-xs mb-4">Please enter the Correct information:</Heading1>}
                                                            <div className="flex justify-between items-start">
                                                                <div>
                                                                    <Heading1 className="font-bold text-xs">Insured Name:</Heading1>
                                                                    <Heading1 className="font-light text-xs">{insuredDetail.name}</Heading1>
                                                                </div>
                                                                <div>
                                                                    <div className="flex items-center relative">
                                                                        <Field onChange={(e: any) => {
                                                                            const { name, value } = e.target;
                                                                            const index = parseInt(name.split('.')[1]); // Extract index from name attribute
                                                                            setValues((prevValues: any) => ({
                                                                                ...prevValues,
                                                                                insuredDetails: prevValues.insuredDetails.map((detail: any, i: number) => {
                                                                                    if (i === index) {
                                                                                        return {
                                                                                            ...detail,
                                                                                            ...questionDetails.insuredDetails[index],
                                                                                            isCorrectInsuredDetails: value === "true"
                                                                                        };
                                                                                    }
                                                                                    return detail;
                                                                                })
                                                                            }));
                                                                        }} name={`insuredDetails.${index}.isCorrectInsuredDetails`} value={true} type="radio" className="w-[12px] h-[12px] text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                        <label htmlFor={`${`insuredDetails.${index}.isCorrectInsuredDetails`}`} className="ms-2 text-xs font-medium text-gray-900 ml-4">Yes</label>
                                                                    </div>
                                                                    <div className="flex items-center relative">
                                                                        <Field onChange={(e: any) => {
                                                                            const { name, value } = e.target;
                                                                            const index = parseInt(name.split('.')[1]); // Extract index from name attribute
                                                                            setValues((prevValues: any) => ({
                                                                                ...prevValues,
                                                                                insuredDetails: prevValues.insuredDetails.map((detail: any, i: number) => {
                                                                                    if (i === index) {
                                                                                        return {
                                                                                            ...detail,
                                                                                            isCorrectInsuredDetails: value === "true"
                                                                                        };
                                                                                    }
                                                                                    return detail;
                                                                                })
                                                                            }));
                                                                        }} name={`insuredDetails.${index}.isCorrectInsuredDetails`} value={false} type="radio" className="w-[12px] h-[12px] text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                        <label htmlFor={`${`insuredDetails.${index}.isCorrectInsuredDetails`}`} className="ms-2 text-xs font-medium text-gray-900 ml-4">No</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {!values.insuredDetails[index].isCorrectInsuredDetails && <div className="mt-2">
                                                                <Heading1 className="font-light text-xs mt-1">Please enter the correct Name</Heading1>
                                                                <Field value={values.insuredDetails[index].name} className='w-full mt-2 text-sm p-2 !w-full border rounded-[8px]' name={`insuredDetails.${index}.name`} />
                                                                <div className="text-xs font-semibold !text-[red]"><ErrorMessage name={`insuredDetails[${index}].name`} /></div>
                                                                <div className="border-t border-dashed border-[#AAB8D7] mt-4"></div>
                                                            </div>}
                                                        </div>
                                                        <div className="w-full mb-2">
                                                            <Heading1 className="font-bold text-xs">Date Of Birth:</Heading1>
                                                            <Heading1 className="font-light text-xs mt-1">{insuredDetail.dob}</Heading1>
                                                            {!values.insuredDetails[index].isCorrectInsuredDetails && <div className="mt-2">
                                                            <Heading1 className="font-light text-xs mt-1">Please enter the correct Date</Heading1>
                                                            <Field type='date' value={values.insuredDetails[index].dob} className='w-full mt-2 text-sm p-2 !w-full border rounded-[8px]' name={`insuredDetails.${index}.dob`} />
                                                            <div className="text-xs font-semibold !text-[red]"><ErrorMessage name={`insuredDetails[${index}].dob`} /></div>
                                                            <div className="border-t border-dashed border-[#AAB8D7] mt-4"></div>
                                                            </div>}
                                                        </div>
                                                        <div className="w-full mb-2">
                                                            <Heading1 className="font-bold text-xs">Relationship with Policyholder:</Heading1>
                                                            <Heading1 className="font-light text-xs mt-1">{insuredDetail.relation}</Heading1>
                                                            {!values.insuredDetails[index].isCorrectInsuredDetails && <div className="mt-2">
                                                                <Heading1 className="font-light text-xs mt-1">Please enter the correct Relationship</Heading1>
                                                            <Field value={values.insuredDetails[index].relation} className='w-full mt-2 text-sm p-2 !w-full border rounded-[8px]' name={`insuredDetails.${index}.relation`} />
                                                            <div className="text-xs font-semibold !text-[red]"><ErrorMessage name={`insuredDetails[${index}].relation`} /></div>
                                                            <div className="border-t border-dashed border-[#AAB8D7] mt-4"></div>
                                                            </div>}
                                                        </div>
                                                        <div className="w-full mb-2">
                                                            <Heading1 className="font-bold text-xs">Policy Inception Date</Heading1>
                                                            <Heading1 className="font-light text-xs mt-1">{insuredDetail.policyInceptionDate}</Heading1>
                                                            {!values.insuredDetails[index].isCorrectInsuredDetails && <div className="mt-2">
                                                                <Heading1 className="font-light text-xs mt-1">Please enter the correct Date</Heading1>
                                                            <Field type='date' value={values.insuredDetails[index].policyInceptionDate} className='w-full mt-2 text-sm p-2 !w-full border rounded-[8px]' name={`insuredDetails.${index}.policyInceptionDate`} />
                                                            <div className="text-xs font-semibold !text-[red]"><ErrorMessage name={`insuredDetails[${index}].policyInceptionDate`} /></div>
                                                            </div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                                <div className="mb-4 shadow-bottom px-4 py-4">
                                <Heading1 className="font-bold text-sm">3) Health Details</Heading1>
                                    {
                                        (questionDetails?.insuredDetails || []).map((insuredDetail: any, index: number) => {
                                            return <div className="w-full">
                                                <div className="relative mt-4">
                                                    <div className="absolute left-[0px] top-[-7px] bg-[#9DB4E5] p-2 w-[10px] h-[10px] flex justify-center items-center font-bold text-xs text-white rounded-[4px]">{index + 1}</div>
                                                    <div className="border-t border-dashed border-[#AAB8D7]"></div>
                                                </div>
                                                <div className="w-full mt-4 flex justify-between items-start">
                                                    <div className="w-full">
                                                        <div className="w-full mb-3">
                                                            <div className="mb-2">
                                                                <Heading1 className="font-bold text-xs">Name</Heading1>
                                                                <Heading1 className="font-light text-xs">{insuredDetail.name}</Heading1>
                                                            </div>
                                                            <div className="mb-2 flex justify-between items-start">
                                                                <div className="">
                                                                    <Heading1 className="font-bold text-xs">Pre-existing Diseases:</Heading1>
                                                                    <Heading1 className="font-light text-xs mt-1">{insuredDetail.preExistingDiseaseName}</Heading1>
                                                                </div>
                                                                <div>
                                                                    <div className="flex items-center relative">
                                                                        <Field onChange={(e: any) => {
                                                                            const { name, value } = e.target;
                                                                            const index = parseInt(name.split('.')[1]); // Extract index from name attribute
                                                                            setValues((prevValues: any) => ({
                                                                                ...prevValues,
                                                                                insuredDetails: prevValues.insuredDetails.map((detail: any, i: number) => {
                                                                                    if (i === index) {
                                                                                        return {
                                                                                            ...detail,
                                                                                            ...questionDetails.insuredDetails[index],
                                                                                            isCorrectDiseaseDetails: value === "true"
                                                                                        };
                                                                                    }
                                                                                    return detail;
                                                                                })
                                                                            }));
                                                                        }} name={`insuredDetails.${index}.isCorrectDiseaseDetails`} value={true} type="radio" className="w-[12px] h-[12px] text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                        <label htmlFor={`${`insuredDetails.${index}.isCorrectDiseaseDetails`}`} className="ms-2 text-xs font-medium text-gray-900 ml-4">Yes</label>
                                                                    </div>
                                                                    <div className="flex items-center relative">
                                                                        <Field onChange={(e: any) => {
                                                                            const { name, value } = e.target;
                                                                            const index = parseInt(name.split('.')[1]); // Extract index from name attribute
                                                                            setValues((prevValues: any) => ({
                                                                                ...prevValues,
                                                                                insuredDetails: prevValues.insuredDetails.map((detail: any, i: number) => {
                                                                                    if (i === index) {
                                                                                        return {
                                                                                            ...detail,
                                                                                            isCorrectDiseaseDetails: value === "true"
                                                                                        };
                                                                                    }
                                                                                    return detail;
                                                                                })
                                                                            }));
                                                                        }} name={`insuredDetails.${index}.isCorrectDiseaseDetails`} value={false} type="radio" className="w-[12px] h-[12px] text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                        <label htmlFor={`${`insuredDetails.${index}.isCorrectDiseaseDetails`}`} className="ms-2 text-xs font-medium text-gray-900 ml-4">No</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {!values.insuredDetails[index].isCorrectDiseaseDetails && <div className="mt-4">
                                                                <Heading1 className="font-light text-xs mt-1">Please enter the Disease(s) Name </Heading1>
                                                                <Field value={values.insuredDetails[index].preExistingDiseaseName} className='w-full mt-2 text-sm p-2 !w-full border rounded-[8px]' name={`insuredDetails.${index}.preExistingDiseaseName`} />
                                                                <div className="text-xs font-semibold !text-[red]"><ErrorMessage name={`insuredDetails[${index}].preExistingDiseaseName`} /></div>
                                                            </div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                                <div className="mb-4 shadow-bottom px-4 py-4">
                                    <Heading1 className="font-bold text-xs mb-4">4) Contact Details</Heading1>
                                    <div className="flex justify-between items-start">
                                        <div>
                                            <Heading1 className="font-bold text-xs">Mobile No:</Heading1>
                                            <Heading1 className="font-light text-xs mt-1">{values.phone}</Heading1>
                                        </div>
                                        <div>
                                            <div className="flex items-center relative">
                                                <Field onChange={(e: any) => {
                                                    setValues((prevValues: any) => ({
                                                        ...prevValues,
                                                        [e.target.name]: e.target.value === "true",
                                                        phone: questionDetails?.phone
                                                    }))
                                                }
                                                } name={'isCorrectContactPhone'} value={true} type="radio" className="w-[12px] h-[12px] text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                <label htmlFor={`${'isCorrectContactPhone'}`} className="ms-2 text-xs font-medium text-gray-900 ml-4">Yes</label>
                                            </div>
                                            <div className="flex items-center relative">
                                                <Field onChange={(e: any) => {
                                                    setValues((prevValues: any) => ({
                                                        ...prevValues,
                                                        [e.target.name]: e.target.value === "true"
                                                    }))
                                                }
                                                } name={'isCorrectContactPhone'} value={false} type="radio" className="w-[12px] h-[12px] text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                <label htmlFor={`${'isCorrectContactPhone'}`} className="ms-2 text-xs font-medium text-gray-900 ml-4">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    {!values?.isCorrectContactPhone && <div className="mt-4 mb-4">
                                        <Heading1 className="text-xs font-normal mb-1">Please enter the correct mobile</Heading1>
                                        <Field value={values.phone} className='text-sm p-2 !w-full border rounded-[8px]' name={'phone'} />
                                        <div className="text-xs font-semibold !text-[red]"><ErrorMessage name={`phone`} /></div>
                                    </div>}
                                    <div className="border-t border-dashed border-[#AAB8D7] mt-4"></div>
                                    <div className="flex justify-between items-start mt-4">
                                        <div>
                                            <Heading1 className="font-bold text-xs">Email Id:</Heading1>
                                            <Heading1 className="font-light text-xs mt-1">{values.email}</Heading1>
                                        </div>
                                        <div>
                                            <div className="flex items-center relative">
                                                <Field onChange={(e: any) => {
                                                    setValues((prevValues: any) => ({
                                                        ...prevValues,
                                                        [e.target.name]: e.target.value === "true",
                                                        email: questionDetails?.email
                                                    }))
                                                }
                                                } name={'isCorrectContactEmail'} value={true} type="radio" className="w-[12px] h-[12px] text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                <label htmlFor={`${'isCorrectContactEmail'}`} className="ms-2 text-xs font-medium text-gray-900 ml-4">Yes</label>
                                            </div>
                                            <div className="flex items-center relative">
                                                <Field onChange={(e: any) => {
                                                    setValues((prevValues: any) => ({
                                                        ...prevValues,
                                                        [e.target.name]: e.target.value === "true"
                                                    }))
                                                }
                                                } name={'isCorrectContactEmail'} value={false} type="radio" className="w-[12px] h-[12px] text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                <label htmlFor={`${'isCorrectContactEmail'}`} className="ms-2 text-xs font-medium text-gray-900 ml-4">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    {!values?.isCorrectContactEmail && <div className="mt-4">
                                        <Heading1 className="text-xs font-normal mb-1">Please enter the correct Email ID</Heading1>
                                        <Field value={values.phone} className='text-sm p-2 !w-full border rounded-[8px]' name={'email'} />
                                        <div className="text-xs font-semibold !text-[red]"><ErrorMessage name={`email`} /></div>
                                    </div>}
                                    <div className="border-t border-dashed border-[#AAB8D7] mt-4"></div>
                                    <div className="flex justify-between items-start mt-4">
                                        <div>
                                            <Heading1 className="font-bold text-xs">Residential Address:</Heading1>
                                            <Heading1 className="font-light text-xs mt-1">{values.address}</Heading1>
                                        </div>
                                        <div>
                                            <div className="flex items-center relative">
                                                <Field onChange={(e: any) => {
                                                    setValues((prevValues: any) => ({
                                                        ...prevValues,
                                                        [e.target.name]: e.target.value === "true",
                                                        address: questionDetails?.address
                                                    }))
                                                }
                                                } name={'isCorrectContactAddress'} value={true} type="radio" className="w-[12px] h-[12px] text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                <label htmlFor={`${'isCorrectContactAddress'}`} className="ms-2 text-xs font-medium text-gray-900 ml-4">Yes</label>
                                            </div>
                                            <div className="flex items-center relative">
                                                <Field onChange={(e: any) => {
                                                    setValues((prevValues: any) => ({
                                                        ...prevValues,
                                                        [e.target.name]: e.target.value === "true"
                                                    }))
                                                }
                                                } name={'isCorrectContactAddress'} value={false} type="radio" className="w-[12px] h-[12px] text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                <label htmlFor={`${'isCorrectContactAddress'}`} className="ms-2 text-xs font-medium text-gray-900 ml-4">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    {!values?.isCorrectContactAddress && <div className="mt-4">
                                        <Heading1 className="text-xs font-normal mb-1">Please enter the correct Residential Address</Heading1>
                                        <Field as='textarea' type='textarea' value={values.address} className='text-sm p-2 !w-full border rounded-[8px]' name={'address'} />
                                        <div className="text-xs font-semibold !text-[red]"><ErrorMessage name={`address`} /></div>
                                    </div>}
                                </div>
                                <div className="mb-4 shadow-bottom px-4 py-4">
                                <Heading1 className="font-bold text-sm">5) Nominee Details</Heading1>
                                    {
                                        (questionDetails?.nomineeDetails || []).map((details: any, index: number) => {
                                            return <div className="w-full">
                                                <div className="relative mt-4">
                                                <div className="absolute left-[0px] top-[-7px] bg-[#9DB4E5] p-2 w-[10px] h-[10px] flex justify-center items-center font-bold text-xs text-white rounded-[4px]">{index + 1}</div>
                                                    <div className="border-t border-dashed border-[#AAB8D7]"></div>
                                                </div>
                                                <div className="w-full mt-4 flex justify-between items-start">
                                                    <div className="w-full">
                                                        <div className="w-full mb-3">
                                                            <div className="mb-2 flex justify-between items-start">
                                                                <div className="">
                                                                    <Heading1 className="font-bold text-xs">Name</Heading1>
                                                                    <Heading1 className="font-light text-xs">{details.nomineeName}</Heading1>
                                                                </div>
                                                                <div>
                                                                    <div className="flex items-center relative">
                                                                        <Field onChange={(e: any) => {
                                                                            const { name, value } = e.target;
                                                                            const index = parseInt(name.split('.')[1]); // Extract index from name attribute
                                                                            setValues((prevValues: any) => ({
                                                                                ...prevValues,
                                                                                nomineeDetails: prevValues.nomineeDetails.map((detail: any, i: number) => {
                                                                                    if (i === index) {
                                                                                        return {
                                                                                            ...detail,
                                                                                            ...questionDetails.nomineeDetails[index],
                                                                                            isCorrectNomineeName: value === "true"
                                                                                        };
                                                                                    }
                                                                                    return detail;
                                                                                })
                                                                            }));
                                                                        }} name={`nomineeDetails.${index}.isCorrectNomineeName`} value={true} type="radio" className="w-[12px] h-[12px] text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                        <label htmlFor={`${`nomineeDetails.${index}.isCorrectNomineeName`}`} className="ms-2 text-xs font-medium text-gray-900 ml-4">Yes</label>
                                                                    </div>
                                                                    <div className="flex items-center relative">
                                                                        <Field onChange={(e: any) => {
                                                                            const { name, value } = e.target;
                                                                            const index = parseInt(name.split('.')[1]); // Extract index from name attribute
                                                                            setValues((prevValues: any) => ({
                                                                                ...prevValues,
                                                                                nomineeDetails: prevValues.nomineeDetails.map((detail: any, i: number) => {
                                                                                    if (i === index) {
                                                                                        return {
                                                                                            ...detail,
                                                                                            isCorrectNomineeName: value === "true"
                                                                                        };
                                                                                    }
                                                                                    return detail;
                                                                                })
                                                                            }));
                                                                        }} name={`nomineeDetails.${index}.isCorrectNomineeName`} value={false} type="radio" className="w-[12px] h-[12px] text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                        <label htmlFor={`${`nomineeDetails.${index}.isCorrectNomineeName`}`} className="ms-2 text-xs font-medium text-gray-900 ml-4">No</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {!values.nomineeDetails[index].isCorrectNomineeName && <div className="mt-4">
                                                                <Heading1 className="font-light text-xs mt-1">Nominee Name </Heading1>
                                                                <Field value={values.nomineeDetails[index].nomineeName} className='w-full mt-2 text-sm p-2 !w-full border rounded-[8px]' name={`nomineeDetails.${index}.nomineeName`} />
                                                                <div className="text-xs font-semibold !text-[red]"><ErrorMessage name={`nomineeDetails[${index}].nomineeName`} /></div>
                                                            </div>}
                                                        </div>
                                                        <div className="w-full mb-3">
                                                            <div className="mb-2 flex justify-between items-start">
                                                                <div className="">
                                                                    <Heading1 className="font-bold text-xs">Relationship:</Heading1>
                                                                    <Heading1 className="font-light text-xs">{details.nomineeRelation}</Heading1>
                                                                </div>
                                                                <div>
                                                                    <div className="flex items-center relative">
                                                                        <Field onChange={(e: any) => {
                                                                            const { name, value } = e.target;
                                                                            const index = parseInt(name.split('.')[1]); // Extract index from name attribute
                                                                            setValues((prevValues: any) => ({
                                                                                ...prevValues,
                                                                                nomineeDetails: prevValues.nomineeDetails.map((detail: any, i: number) => {
                                                                                    if (i === index) {
                                                                                        return {
                                                                                            ...detail,
                                                                                            ...questionDetails.nomineeDetails[index],
                                                                                            isCorrectNomineeRelation: value === "true"
                                                                                        };
                                                                                    }
                                                                                    return detail;
                                                                                })
                                                                            }));
                                                                        }} name={`nomineeDetails.${index}.isCorrectNomineeRelation`} value={true} type="radio" className="w-[12px] h-[12px] text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                        <label htmlFor={`${`nomineeDetails.${index}.isCorrectNomineeRelation`}`} className="ms-2 text-xs font-medium text-gray-900 ml-4">Yes</label>
                                                                    </div>
                                                                    <div className="flex items-center relative">
                                                                        <Field onChange={(e: any) => {
                                                                            const { name, value } = e.target;
                                                                            const index = parseInt(name.split('.')[1]); // Extract index from name attribute
                                                                            setValues((prevValues: any) => ({
                                                                                ...prevValues,
                                                                                nomineeDetails: prevValues.nomineeDetails.map((detail: any, i: number) => {
                                                                                    if (i === index) {
                                                                                        return {
                                                                                            ...detail,
                                                                                            isCorrectNomineeRelation: value === "true"
                                                                                        };
                                                                                    }
                                                                                    return detail;
                                                                                })
                                                                            }));
                                                                        }} name={`nomineeDetails.${index}.isCorrectNomineeRelation`} value={false} type="radio" className="w-[12px] h-[12px] text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                        <label htmlFor={`${`nomineeDetails.${index}.isCorrectNomineeRelation`}`} className="ms-2 text-xs font-medium text-gray-900 ml-4">No</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {!values.nomineeDetails[index].isCorrectNomineeRelation && <div className="mt-4">
                                                                <Heading1 className="font-light text-xs mt-1">Nominee Name </Heading1>
                                                                <Field value={values.nomineeDetails[index].nomineeRelation} className='w-full mt-2 text-sm p-2 !w-full border rounded-[8px]' name={`nomineeDetails.${index}.nomineeRelation`} />
                                                                <div className="text-xs font-semibold !text-[red]"><ErrorMessage name={`nomineeDetails[${index}].nomineeRelation`} /></div>
                                                            </div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                    }
                                    </div>
                                <nav className="fixed bottom-0 right-0 left-0 bg-white  rounded-t-lg lg:pt-4">
                                    <CouponField touched={touched} errors={errors} redeemValue={redeemValue} redeemColor={redeemColor} couponHandler={() => couponHandler(values)} setRedeemValue={setRedeemValue} isKYPFree={isKYPFree} handlePayment={() => handlePayment(values)} />
                                </nav>
                            </form>
                        }}
                    </Formik>
            }
        </ContainerOne>
    </div>
    </>
}

export default Questionare
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import React, { Suspense, useEffect } from 'react';
import allRoutes from './routes/routes';
import { getDataInLocalStorage, removeDataInLocalStorage } from './helpers/Utils';

const routes = createBrowserRouter(allRoutes);
const App: React.FC = () => {
  useEffect(() => {
    removeDataInLocalStorage('token')
    if (!getDataInLocalStorage('token')) {
      if (!['/', '/otp'].includes(window.location.pathname)) {
        window.location.href = window.location.origin
      }
    }
  }, [])

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <div className="sm:pl-20 lg:pl-4 lg:flex lg:flex-col lg:max-w-[600px] lg:mx-auto">
        <RouterProvider router={routes} />
      </div>
    </Suspense>
  );
};

export default App;

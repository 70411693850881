import React from 'react';
import { InputProps } from './Types'

const FormInput: React.FC<InputProps> = ({ error = '', label = "", limit, name, message = "", value, dob, placeholder, onChange, type = "text", isDisabled = false, classess, ...props }) => {
  const maxDate = dob ? new Date().toISOString().split('T')[0] : undefined;
  
  return (
    <div className={`${classess}`}>
      <label className={`block mb-2 text-xs font-bold opacity-70 ${classess}`} htmlFor={name}>{label}</label>
      <input style={{ color: isDisabled ? "black" : "" }} className={`py-2 text-sm pl-4 w-full bg-white border border-gray-300 rounded-md focus:ring-blue-500 p-2 ${classess} ${isDisabled ? "bg-gray-300 font-medium" : ""}`} max={maxDate} type={type} 
      id={name} name={name} value={value}  onChange={(e: any) => {
        if (limit && e.target.value.length > limit) return;
        if (onChange && typeof onChange === 'function') {
            onChange(e);
        }
    }} placeholder={placeholder} disabled={isDisabled} {...props} />
      {error ? <span className='text-red-700 font-semibold text-xs'>{error}</span> : null}
    </div>
  )
}

export default FormInput

import React, { ReactNode } from 'react'
interface ModalProps {
    showModal: boolean,
    toggle?: any,
    children: ReactNode,
    className?: string
}
const Modal: React.FC<ModalProps> = ({ showModal, children, className, toggle }) => {
    return (
        <div className={`${showModal ? "flex" : "hidden"} backdrop-blur-sm backdrop-brightness-50  overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full min-w-36 md:inset-0 h-full`}>
            <div className={`bg-white min-h-40 rounded-lg p-3 shadow-lg ${className}`}>
                {children}
            </div>
        </div>
    )
}
export default Modal
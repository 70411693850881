import { ButtonType, buttonColorType } from './Types'

function DarkButton({ loading = false, label = "", type = 'button', className = "", buttonClassName = "", onclick = () => { console.log("No Click Function in passed") }, color = "dark", children, isDisable, ...props }: ButtonType) {

  const buttonType: buttonColorType = {
    dark: "font-bold text-white  focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-8 py-2.5 me-2 mb-2 bg-gray-800 hover:bg-gray-900 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700",
    light: "font-bold text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-full text-sm px-5 py-2.5 me-2 mb-2 ",
    blue:"text-white bg-green-700 from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-0 focus:outline-none font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2",
    polifyx: "bg-polifyx !rounded-[8px] focus:ring-0 focus:outline-none font-medium rounded-full text-sm py-2.5 text-center me-2 mb-2 !font-bold",
  }

  return (
    <div className={`bg-white rounded flex justify-end col-span-2 mt-6 xl:mt-0 lg:mt-0 2xl:mt-0 ${className}`}>
      <button {...props} type={type} 
        className={`col-span-1 flex justify-center w-full lg:w-auto font-bold 
          ${buttonClassName} 
          ${(buttonType as any)[color]} mr-0 ${isDisable ? "opacity-25" : ""} `}
          onClick={onclick}
          disabled={isDisable}
        >
        {label}
        {children}
      </button>
    </div>
  )
}

export default DarkButton

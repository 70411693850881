/** @format */

import React, { useEffect, useState } from "react";
import Heading1 from "../../component/semantic/Heading1";
import ContainerOne from "../../component/common/ContainerOne";
import { DeleteIcon, HealthIcon, LifeIcon } from "./IconProvider";
import { HiMiniInformationCircle } from "react-icons/hi2";
import Select from 'react-select';
import ImageIcon from './image.png'
import pdfIcon from './pdf.png'
import jsPDF from 'jspdf';
import BottomContent from "../../layout/BottomContent";
import DarkButton from "../../component/semantic/DarkButton";
import { getCompanyList, uploadPolicy } from "../../services/request";
import { print } from "../../helpers/Utils";
import { useDispatch, useSelector } from "react-redux";
import { handlePolicy, handlePolicyDetails, handleRoute, handleselectFilesDetails } from "../../store/user/user.slice";
import { RootState } from "../../store/store";
import UploadImage from "./UploadImage";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import imageCompression from 'browser-image-compression';

const PolicyDetails: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [loader, setLoader] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const { policyDetails, currentUser, selectFiles, type } = useSelector((state: RootState) => state.user);
  // const [selectedFiles, setSelectedFiles] = useState<any>([]);
  // const [type, setType] = useState(true);
  const [companyList, setCompanyList] = useState<any>([]);

  const handleFileSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files: any = event.target.files;
    if (files && files.length > 0) {
      dispatch(handleselectFilesDetails([ ...files ]));
    }
  };

  const handlePDFClick = () => {
    const input: any = document.querySelector("#pdf-picker-hander");
    input.type = "file";
    input.accept = ".pdf";
    // input.capture = "environment";
    input.click();
  };

  // const submitHandler = async () => {
  //   if (!policyDetails?.companyId?.value) {
  //     toast.error('Please select insurance company!!')
  //     return;
  //   }
  //   if (selectFiles.length === 0) {
  //     toast.error('Please uplaod policy!!')
  //     return;
  //   }
  //   setLoader(true)
  //   const formData = new FormData();
  //   if (!type) {
  //     const doc = new jsPDF();
  //     selectFiles.forEach((image: any, index: number) => {
  //       if (index > 0) {
  //         doc.addPage();
  //       }
  //       doc.text(image.name, 10, 10);
  //       const imgData = URL.createObjectURL(image);
  //       doc.addImage(imgData, 'PNG', 10, 20, 180, 160);
  //     });
  //     const pdfBlob = doc.output('blob');
  //     formData.set("file", pdfBlob, 'policy_images.pdf');
  //   } else {
  //     formData.set("file", selectFiles[0]);
  //   }
  
  //   formData.set("policyNumber", policyDetails.policyNumber )
  //   formData.set("userId",  currentUser?._id)
  //   formData.set("insuranceCompanyId", policyDetails?.companyId?.value )
  //   formData.set("source", 'kyp');
  //   formData.set("owner", currentUser?._id);
  //   // User Id remains 
  //   const resData = await uploadPolicy(formData)
  //   if (resData && resData?.success) {
  //     dispatch(handlePolicy(resData?.data))
  //     setLoader(false)
  //     navigate('/loading')
  //   }
  //   setLoader(false)
  //   // dispatch(handleRoute(2))
  // };
  const submitHandler = async () => {
    if (!policyDetails?.companyId?.value) {
      toast.error('Please select insurance company!!');
      return;
    }
    if (selectFiles.length === 0) {
      toast.error('Please upload policy!!');
      return;
    }
    setLoader(true);
    const formData = new FormData();
  
    // Compress and add images to FormData
    const compressedFiles = [];
    // for (const file of selectFiles) {
    //   const options = {
    //     maxSizeMB: 5, // Maximum size in MB
    //     maxWidthOrHeight: 1920, // Maximum width or height of the image
    //     useWebWorker: true // Use Web Workers for faster compression
    //   };
    //   try {
    //     const compressedFile = await imageCompression(file, options);
    //     compressedFiles.push(compressedFile);
    //   } catch (error) {
    //     console.error('Image compression error:', error);
    //   }
    // }
  
    if (!type) {
      for (const file of selectFiles) {
        const options = {
          maxSizeMB: 10, // Maximum size in MB
          maxWidthOrHeight: 1920, // Maximum width or height of the image
          useWebWorker: true // Use Web Workers for faster compression
        };
        try {
          const compressedFile = await imageCompression(file, options);
          compressedFiles.push(compressedFile);
        } catch (error) {
          console.error('Image compression error:', error);
        }
      }
      const doc = new jsPDF();
      compressedFiles.forEach((image, index) => {
        if (index > 0) {
          doc.addPage();
        }
        doc.text(image.name, 10, 10);
        const imgData = URL.createObjectURL(image);
        doc.addImage(imgData, 'PNG', 10, 20, 180, 160);
      });
      const pdfBlob = doc.output('blob');
      formData.set('file', pdfBlob, 'policy_images.pdf');
    } else {
      formData.set('file', selectFiles[0]);
    }
  
    formData.set('policyNumber', policyDetails.policyNumber);
    formData.set('userId', currentUser?._id);
    formData.set('insuranceCompanyId', policyDetails?.companyId?.value);
    formData.set('source', 'kyp');
    formData.set('kypSrc', 'web');
    formData.set('owner', currentUser?._id);
  
    try {
      const resData = await uploadPolicy(formData);
      if (resData && resData.success) {
        dispatch(handlePolicy(resData.data));
        setLoader(false);
        navigate('/loading');
      }
    } catch (error) {
      console.error('Policy upload error:', error);
      setLoader(false);
    }
  };
  const getCompanyListFunc = async () => {
    try {
      const data = await getCompanyList(activeTab == 0 ? "3030b68f-82e9-4dfb-b2b8-82b743d0692a" : "864a375b-5fa7-49c7-9f39-2b1fda4674b5")
      setCompanyList(data.data?.filter(((res:any)=>res.docEyeImpStatus)).map((res:any) => {return {label:res.name, value: res._id}}));
      // dispatch(handlePolicyDetails({ companyId: '' }))
    } catch (error) {
      print(error)
    }
  }

  useEffect(() => {
    getCompanyListFunc();
  }, [activeTab])

  const changeHandler = (e : any, name="") => {
    if(name == "companyId"){
      dispatch(handlePolicyDetails({ companyId: e }))
      return;
    }
  }

  const handleDelete = (index: number) => {
    const newFiles = [...selectFiles];
    newFiles.splice(index, 1);
    dispatch(handleselectFilesDetails(newFiles));
  };
  const handleClearFile = () => {
    const fileInput = document.getElementById('pdf-picker-hander') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
  };
  return (
    <div className="ml-2 py-5 pt-2 mx-2 mb-[80px]">
      <Heading1 className="font-extrabold text-sm">Policy Details</Heading1>
      <ContainerOne className="!bg-[unset] border border-[#D8DDE9] mt-2 !p-4">
        <Heading1 className="font-semibold text-xs">Insurance Type *</Heading1>
        <div className="flex gap-6">
          <div className="flex flex-col items-center">
            <div
              className={`p-[12px] shadow-icon rounded-[8px] ${
                activeTab == 0 ? "bg-icon" : ""
              }`}
              onClick={() => (dispatch(handlePolicyDetails({ companyId: '' })), setActiveTab(0))}
            >
              <HealthIcon
                activeColor={activeTab === 0 ? "white" : "#CFDAF3"}
                secondActiveColor={activeTab === 0 ? "#FAAA52" : "#8B9FCD"}
              />
            </div>
            <Heading1 className={`text-xs font-semibold mt-2 ${activeTab == 0 ? "!font-bold" : ""}`}>Health</Heading1>
          </div>
          <div className="flex flex-col items-center">
            <div
              className={`p-[12px] shadow-icon rounded-[8px] ${
                activeTab == 1 ? "bg-icon" : ""
              }`}
              onClick={() => (dispatch(handlePolicyDetails({ companyId: '' })), setActiveTab(1))}
            >
              <LifeIcon
                activeColor={activeTab === 1 ? "white" : "#CFDAF3"}
                secondActiveColor={activeTab === 1 ? "#FAAA52" : "#8B9FCD"}
              />
            </div>
            <Heading1 className={`text-xs font-semibold mt-2 ${activeTab == 1 ? "!font-bold" : ""}`}>Life</Heading1>
          </div>
        </div>
        <div className="">
          <Heading1 className="text-xs font-semibold mb-2">
            Insurance Company
          </Heading1>
          <Select
            value={policyDetails.companyId}
            name="companyId"
            className=""
            classNamePrefix="select"
            isSearchable={false}
            onChange={(e) => {changeHandler(e, "companyId")}}
            options={companyList}
            placeholder="Select Company"
          />
        </div>
        <div className="flex gap-2 items-start justify-start">
          <div style={{ fontSize: "18px" }}>
            <HiMiniInformationCircle color="#09090999" />
          </div>
          <Heading1 className="opacity-70 text-xs font-[12px]">
            Please note If you are unable to find your insurance company, it
            means that this feature will be available for your insurance company
            soon.
          </Heading1>
        </div>
      </ContainerOne>
      <ContainerOne className="!bg-[unset] border border-[#D8DDE9] mt-6 !p-0 rounded-[8px] mb-[40px]">
        <div className="bg-[#F9F9F9] p-4">
          <Heading1 className="font-extrabold text-sm">Upload Policy </Heading1>
        </div>
        <div className="flex gap-4 p-2 pb-2 pl-4">
          <div className="flex flex-col items-center" onClick={() => (navigate('/upload'))}>
            <div className="p-4 shadow-icon rounded-[10px]">
              <img src={ImageIcon} className="w-[32px] h-[32px]" />
            </div>
            <Heading1 className="text-xs font-bold mt-2">Image</Heading1>
          </div>
          <div className="flex flex-col items-center" onClick={handlePDFClick}>
            <div className="p-4 shadow-icon rounded-[10px]">
              <img src={pdfIcon} className="w-[32px] h-[32px]" />
            </div>
            <Heading1 className="text-xs font-bold mt-2">PDF</Heading1>
          </div>
        </div>
          <input
            type="file"
            id="pdf-picker-hander"
            accept=".pdf"
            style={{ display: "none" }}
            onChange={handleFileSelected}
          />
        <div className="px-4 pb-2">
            {
              (selectFiles || []).map((file: any, index: number) => {
                return <div className="flex justify-between items-center mb-2" key={index}>
                <Heading1 className="font-bold text-xs break-words">{file.name}</Heading1>
                  <DeleteIcon
                    className=""
                    onClick={() =>{ handleDelete(index);handleClearFile() }}
                  />
                </div>
              })
            }
        </div>
      </ContainerOne>
      <BottomContent>
        <DarkButton isDisable={loader} color="polifyx" label="NEXT" onclick={submitHandler} buttonClassName="px-20"  />
      </BottomContent>
    </div>
    // : <UploadImage setUploadScreen={(val: boolean) => dispatch(handleIsUploadScreen(val))} />
  );
};

export default PolicyDetails;

import React, { HTMLAttributes } from 'react'

type ContainerPropDiv =HTMLAttributes<HTMLDivElement> 

const ContainerOne: React.FC<ContainerPropDiv> = ({children, className = "",...props}) => {
  return (
    <div className={`grid gap-x-10 gap-y-5 lg:grid-cols-1 md:grid-cols-1 bg-white rounded-lg py-10 px-9 ${className}`} {...props}>
      {children}
    </div>
  )
}

export default ContainerOne
import React from 'react'
import Icon from "./Icon.svg";
import DarkButton from '../../component/semantic/DarkButton';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';

function CouponField({redeemValue, redeemColor, couponHandler, setRedeemValue, isKYPFree, handlePayment, errors, touched}:any) {
    const user = useSelector((state: RootState) => state.user)
    const partnerFee = user?.partnerDetails?.kypFees
    const userCreatedBy = user?.currentUser?.userCreatedBy
  return (
    <div className="">
        <div className="border rounded-t-[20px]  bottom-0 w-full shadow-2xl p-4 relative">
            <div className="">
                <label className={`block mb-2 text-xs font-bold opacity-70`}>{"Have a coupon code?"}</label>
                <input value={redeemValue} className={` ${redeemColor.inputBackground} py-2 text-sm pl-4 w-full bg-white border border-gray-300 rounded-md focus:ring-blue-500 p-2 outline-0 border-b-4 border-[#FAAA52]-500`} placeholder="Enter Coupon Code" onChange={(e) => setRedeemValue(e.target.value)} disabled={redeemColor?.redeemSuccess} />
                <button disabled={!redeemValue} onClick={couponHandler} color='polifyx' className="bg-inherit absolute top-[30%] right-[10%] text-polifyx">{redeemColor.textColor ? <span className={`font-semibold text-[${redeemColor.textColor}] cursor-pointer`}>Redeem</span> : <img alt="check" src={Icon} />}</button>
            </div>
            {
                isKYPFree ? 
                <DarkButton isDisable={Object.keys(errors||{})?.length > 0 && Object.keys(touched||{})?.length > 0} onclick={couponHandler} label='Proceed to check result' color='polifyx' buttonClassName='px-8' /> :
                <DarkButton isDisable={Object.keys(errors||{})?.length > 0 && Object.keys(touched||{})?.length > 0} onclick={handlePayment} label={`Pay Rs. ${userCreatedBy ? 99 : 129} to generate the result`} color='polifyx' buttonClassName='px-8' />
            }
        </div>
    </div>
  )
}

export default CouponField
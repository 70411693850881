import axios, { AxiosError } from "axios";
import { baseUrl } from "../defaultValues/constants.services";

export const checkUserAuthentication = () => {
    try {
        const user = localStorage.getItem("user_potal_accessor")
        return Boolean(user)
    } catch (error) {
        print(error);
    }
}

export type ActiveUserType = {
    userType?: string,
    token?: string,
    completeRegistration?: string | boolean,
    DBNF?: string,
    streetArea?: string,
    CTPV?: string,
    talukTehsil?: string,
    email?: string,
    phone?: string,
    "address"?: null,
    "isPaymentDone"?: boolean,
    "leadId"?: string,
    "userId"?: string,
    "contractString"?: string,
    isContractSigned?: boolean,
}

export const getCurrentUser: any = () => {
    try {
        const userDataJSON = localStorage.getItem("user_portal_accessor") || "";
        let data: ActiveUserType | null = null;
        if (userDataJSON) {
            data = userDataJSON ? JSON.parse(userDataJSON) : null;
        }
        return data
    } catch (error) {
        return null
        console.error(error);
    }
}

export const print = (...props: any) => {
    if (process.env.REACT_APP_ENV != "prod") {
        console.log({ ...props });
    }
}

export const mobileInputValidation = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (["ArrowUp", "ArrowDown", "-", "+", "e", "-", "."].includes(e.key)) {
        e.preventDefault()
        return;
    }
    return (e.target as HTMLInputElement).value?.length >= 10 ? ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"].includes("" + e.key) ? e.preventDefault() : null : ""
}

export const getDateWithFormat = () => {
    const today = new Date();
    let dd = String(today.getDate());
    let mm = String(today.getMonth() + 1); // January is 0!

    const yyyy = today.getFullYear();
    if (Number(dd) < 10) {
        dd = `0${dd}`;
    }
    if (Number(mm) < 10) {
        mm = `0${mm}`;
    }
    return `${dd}.${mm}.${yyyy}`;
};

export const mapOrder = (array: [], order: string, key: string) => {
    array.sort(function (a, b) {
        const A = a[key];
        const B = b[key];
        if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
            return 1;
        }
        return -1;
    });
    return array;
};
// eslint-disable-next-line no-useless-escape
export const mobileValidation = (num: string) => { return /^[6789]\d{9}$/.test(num); }
export const emailValidation = (email: string) => { return /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(email); }
export const pinValidation = (pin: string) => { return /^\d{6}$/.test(pin) };
export const nameValidation = (name: string) => { /^[a-zA-Z ]+$/.test(name); }
export const numberValidation = (num: string) => { /^\d+$/.test(num) };
export const panValidation = (pan: string) => { /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/.test(pan) };

export const validatePositiveInteger = (number: any) =>{
    // Check if the input is a number
    if (typeof number !== 'number') {
        return false;
    }
    
    // Check if the number is an integer
    if (!Number.isInteger(number)) {
        return false;
    }
    
    // Check if the number is positive
    if (number <= 0) {
        return false;
    }
    
    return true;
}

// export const sanitizeFileName = (fileName: string) => {
//     let fileNameArray = fileName.split('.');
//     return fileNameArray[0].replace(/[/\\?%*:|"<>-_ ]+/g, '').trim() || "file";
// }

// export const IOSToLocal = (date: string) => {
//     let hours = (new Date(date)).getHours() + 5;
//     let minutes = (new Date(date)).getMinutes() + 30;
//     let fTempDate = new Date(date).setHours(hours)
//     let temp = new Date(new Date(fTempDate).setMinutes(minutes)).toISOString()?.slice(0, 16)?.replace("T", " : ")
//     return temp;
// }

// export const futureDateValidation = (date: string) => {
//     if (date) {
//         let enteredDate = new Date(date).getTime();
//         let currentDate = new Date().getTime();
//         if (enteredDate > currentDate) {
//             return true;
//         } else {
//             return false;
//         }
//     }
//     else {
//         return true;
//     }
// }

const a:any = [
    "",
    "one ",
    "two ",
    "three ",
    "four ",
    "five ",
    "six ",
    "seven ",
    "eight ",
    "nine ",
    "ten ",
    "eleven ",
    "twelve ",
    "thirteen ",
    "fourteen ",
    "fifteen ",
    "sixteen ",
    "seventeen ",
    "eighteen ",
    "nineteen ",
];

const b:any = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
];

export const amtInWord = (num: string) => {
    if(!num) {return;}
    if ((num = num?.toString())?.length > 9) return "overflow";
    const n = ("000000000" + num)
        .substr(-9)
        .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return;
    let str = "";
    str +=
        Number(n[1]) != 0
            ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
            : "";
    str +=
    Number(n[2]) != 0
            ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh "
            : "";
    str +=
    Number(n[3]) != 0
            ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
            : "";
    str +=
    Number(n[4]) != 0
            ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
            : "";
    str +=
    Number(n[5]) != 0
            ? (str != "" ? "and " : "") +
            (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
            "only "
            : "";
    return str;
};

export const setDataInLocalStorage = (key: string, data: any) => {
    localStorage.setItem(key, data)
}
export const getDataInLocalStorage = (key: string) => {
    return !!localStorage.getItem(key) ? JSON.parse(JSON.stringify(localStorage.getItem(key)) as any) : null
}

export const removeDataInLocalStorage = (key: string) => {
    return localStorage.removeItem(key);
}

export const formatDate = (dateString: string) => {
    if (!dateString) return ""; // Return empty string if date is not provided
        const [dd, mm, yyyy] = dateString.split("/"); // Split date string by "/"
    return `${yyyy}-${mm?.padStart(2, "0")}-${dd?.padStart(2, "0")}`; // Format as "YYYY-MM-DD"
  };
import * as Yup from "yup";

export const healthValidation = Yup.object().shape({
    isCorrectName: Yup.boolean().required(),
    policyHolderName: Yup.string().when(
        'isCorrectName',
        {
            is:(value:boolean)=>value === false,
            then: (schema: any) => Yup.string().required('Policy Holder Name is required'),
            otherwise: (schema: any) => schema.notRequired()
        }
    ),
    
    insuredDetails: Yup.array().of(
        Yup.object().shape({
            isCorrectDiseaseDetails: Yup.boolean().required(),
            isCorrectInsuredDetails: Yup.boolean().required(),
                name: Yup.string().when(
                    'isCorrectInsuredDetails',
                    {
                        is:(value:boolean)=>value === false,
                        then: (schema: any) => Yup.string().required('Name is required'),
                        otherwise: (schema: any) => schema.notRequired()
                    }
                ),
                dob: Yup.string().when(
                    'isCorrectInsuredDetails',
                    {
                        is:(value:boolean)=>value === false,
                        then: (schema: any) => Yup.string().required('Date of birth is required'),
                        otherwise: (schema: any) => schema.notRequired()
                    }
                ),
                relation: Yup.string().when(
                    'isCorrectInsuredDetails',
                    {
                        is:(value:boolean)=>value === false,
                        then: (schema: any) => Yup.string().required('Relation is required'),
                        otherwise: (schema: any) => schema.notRequired()
                    }
                ),
                policyInceptionDate: Yup.string().when(
                    'isCorrectInsuredDetails',
                    {
                        is:(value:boolean)=>value === false,
                        then: (schema: any) => Yup.string().required('Policy Inception Date is required'),
                        otherwise: (schema: any) => schema.notRequired()
                    }
                ),
                preExistingDiseaseName: Yup.string().when(
                    'isCorrectDiseaseDetails',
                    {
                        is:(value:boolean)=>value === false,
                        then: (schema: any) => Yup.string().required('Pre-existing disease name is required'),
                        otherwise: (schema: any) => schema.notRequired()
                    }
                )
        })
    ),
    nomineeDetails: Yup.array().of(
        Yup.object().shape({
            isCorrectNomineeName: Yup.boolean().required(),
            isCorrectNomineeRelation: Yup.boolean().required(),
            nomineeName: Yup.string().when(
                'isCorrectNomineeName',
                {
                    is:(value:boolean)=>value === false,
                    then: (schema: any) => Yup.string()
                    .required('Nominee Name is required'),
                    otherwise: (schema: any) => schema.notRequired()
                }
            ),
            nomineeRelation: Yup.string().when(
                'isCorrectNomineeRelation',
                {
                    is:(value:boolean)=>value === false,
                    then: (schema: any) => Yup.string()
                    .required('Nominee relation is required'),
                    otherwise: (schema: any) => schema.notRequired()
                }
            )
        })
    ),
    isCorrectContactPhone: Yup.boolean().required(),
    isCorrectContactEmail: Yup.boolean().required(),
    isCorrectContactAddress: Yup.boolean().required(),
    
    email: Yup.string().when(
        'isCorrectContactEmail',
        {
            is:(value:boolean)=>value === false,
            then: (schema: any) => Yup.string()
            .email('Invalid email format')
            .required('Email is required'),
            otherwise: (schema: any) => schema.notRequired()
        }
    ),
    address: Yup.string().when(
        'isCorrectContactAddress',
        {
            is:(value:boolean)=>value === false,
            then: (schema: any) => Yup.string()
            .min(5, 'Address is too short')
            .required('Address is required'),
            otherwise: (schema: any) => schema.notRequired()
        }
    ),
    phone: Yup.string().when(
        'isCorrectContactPhone',
        {
            is:(value:boolean)=>value === false,
            then: (schema: any) => Yup.string()
            .matches(/^[0-9]{10}$/, 'Invalid phone number')
        .required('Phone is required'),
            otherwise: (schema: any) => schema.notRequired()
        }
    )
});
export const lifeValidation = Yup.object().shape({
    POLYFYX_QB2: Yup.boolean().required(),
    POLYFYX_QB2_2: Yup.object().when('POLYFYX_QB2', {
        is: (value: any) => value === true,
        then: (schema: any) => schema.shape({
            PolicyNumber: Yup.string()
                .required("Policy Number is required"),
            SumInsured: Yup.number()
                .required('sumInsured is required'),
            InsuranceCompanyName: Yup.string()
                .required('Select insurance company name'),
        }),
        otherwise: (schema: any) => schema.notRequired()
    }),
    POLYFYX_QC1: Yup.boolean().required(),
    POLYFYX_QC1_2: Yup.object().when('POLYFYX_QC1', {
        is: (value: any) => value === true,
        then: (schema: any) => schema.shape({
            PolicyNumber: Yup.string()
                .required("Policy Number is required"),
            SumInsured: Yup.number()
                .required('sumInsured is required'),
            InsuranceCompanyName: Yup.string()
                .required('Select insurance company name'),
        }),
        otherwise: (schema: any) => schema.notRequired()
    }),
    POLYFYX_QF2: Yup.boolean().required(),
    POLYFYX_QF2_2: Yup.object().when('POLYFYX_QF2', {
        is: (value: any) => value === true,
        then: (schema: any) => schema.shape({
            Occupation: Yup.string()
            .required("occupation is required")
        }),
        otherwise: (schema: any) => schema.notRequired()
    }),
    POLYFYX_QC3: Yup.boolean().required(),
    POLYFYX_QC3_1: Yup.object().when('POLYFYX_QC3', {
        is: (value: any) => value === false,
        then: (schema: any) => schema.shape({
            MobileNumber: Yup.string()
            .required("Mobile Number is required")
            .min(10, 'Mobile number must be of 10-digits')
            .max(10, 'Mobile number must be of 10-digits')
        }),
        otherwise: (schema: any) => schema.notRequired()
    }),
    POLYFYX_QC4:Yup.boolean().required(),
    POLYFYX_QC4_1: Yup.array().of(Yup.object().when('POLYFYX_QC4', {
        is: (value: any) => value === true,
        then: (schema: any) => schema.shape({
            NomineeName: Yup.string()
            .required("Nominee Name is required"),
        }),
        otherwise: (schema: any) => schema.notRequired()
    }),
    )
})

import { useEffect } from 'react'
import Heading1 from '../../component/semantic/Heading1'
import tryAgain from './tryAgain.png'
import { getPolicyDetails } from '../../services/request'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { handleRoute } from '../../store/user/user.slice'
import SocketComponent from '../../socket/socket.component'
import DarkButton from '../../component/semantic/DarkButton'
import { useNavigate } from 'react-router-dom'

const TryAgain = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { policy } = useSelector((state: RootState) => state.user)

    return <div className="p-12">
        <div className=''>
            <img src={tryAgain} className='w-[145px] h-[178px] mx-auto' />
        </div>
        <div className='mt-8'>
            <Heading1 className='mb-2 text-center font-extrabold text-sm'>Uh Oh! Please try again.</Heading1>
            <Heading1 className='mb-2 text-center font-normal text-sm'>Unfortunately, we're unable to process<br/>your request at the moment. Please upload the complete policy pack PDF of your insurance policy for accurate results.</Heading1>
            <div className='px-10' onClick={() => navigate('/')}>
               <DarkButton type={'submit'} color="polifyx" label="Try Again" />
            </div>
        </div>
    </div>
}

export default TryAgain
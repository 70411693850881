// SocketComponent.js
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import io from 'socket.io-client';
import { getPolicyDetails } from '../services/request';
import { RootState } from '../store/store'
import { handleRoute } from '../store/user/user.slice';
import Modal from '../component/common/Modal';
import Heading1 from '../component/semantic/Heading1';
import { RxCrossCircled } from 'react-icons/rx';
import DarkButton from '../component/semantic/DarkButton';
const SOCKET_URL = process.env.REACT_APP_SOCKETURL;

const SocketComponent = ({userObj}:any) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [openModal, setOpenModal] = useState(false);
    const openModals = () => setOpenModal(true)
    const closeModal = () => setOpenModal(false)
    const { policy } = useSelector((state: RootState) => state.user)
    const fetchPolicyDetails = async () => {
        const resp = await getPolicyDetails(policy?._id)

        if (resp && resp?.success) {
            navigate('/policy-details')
            // dispatch(handleRoute(3))
        }
    }
    // const goHome = ()=>{
    //     setOpenModal(false)
    //     navigate('/')
    // }
  useEffect(() => {
      if (SOCKET_URL) {
          const socket = io(SOCKET_URL || "");
          socket.on('connect', () => {
              console.log('connected to socket')

              socket.on('KYP_RESULT_READY', async (data: any) => {
                  try {
                      console.log(data.userId, 'User Id Recieved from webhook')
                      if(data.userId){
                          fetchPolicyDetails()
                      }else{
                        navigate('/tryAgain')
                      }
                  } catch (error) {
                      console.log(error, "-------from socket")
                  }
              })
          })

          return () => {
              socket.disconnect(); // Clean up the socket connection
          };
      }
  }, []);
  
  return <>
  {/* {
    <Modal showModal={openModal}>
    <div className="w-[70vw]">
    <div className="flex justify-end w-full" onClick={closeModal}><RxCrossCircled /></div>
    <Heading1 className="font-bold text-sm">Document is not proper please try again with correct document.</Heading1>
    <DarkButton color="polifyx" label="Go home" onclick={()=>{goHome()}} buttonClassName="px-10" />
    </div>
  </Modal>
  } */}
  </>; // This component doesn't render anything visible
};

export default SocketComponent;

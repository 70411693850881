/** @format */

import Heading1 from "../../component/semantic/Heading1";
import kpy from "./kpy.png";
import Rectangle from "./Rectangle.png";
import error from "./error.png";
import warning from "./warning.png";
import advice from "./advice.png";
import { FaRegCopy } from "react-icons/fa";
import Modal from "../../component/common/Modal";
import { RxCrossCircled } from "react-icons/rx";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { getHealthQualityResult, lifeQualityCheckResult } from "../../services/request";
import HealthyResult from "./healthyResult";
import CriticalReport from "./criticalResult";
import AttentionReport from "./attentionReport";


const ResultScreen = () => {

  const dispatch = useDispatch();
  const { policy,draftDetails } = useSelector((state: RootState) => state.user);
  const [resultData, setResultData] = useState<any>({});

  const fetchDetails = async () => {
    if (policy?.policyTypeId?._id === "864a375b-5fa7-49c7-9f39-2b1fda4674b5") {
      const data = await lifeQualityCheckResult({ policyId: policy?._id });
      setResultData(data?.data)
    } else {
      const data = await getHealthQualityResult(policy?._id);
      setResultData(data?.data);
    }
  }
  useEffect(() => {
    fetchDetails();
  }, [])

  return (
    <div className="relative">
      {
        resultData?.ResultTitle === "Healthy" ?
          <HealthyResult policy={resultData} />
          : resultData?.ResultTitle === "Critical" ? <CriticalReport policy={resultData} draftDetails={draftDetails}/> : <AttentionReport policy={resultData} draftDetails={draftDetails}/>
      }
      {
        resultData?.ResultTi
      }
    </div>
  );
};

export default ResultScreen;

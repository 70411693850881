import Heading1 from "../../component/semantic/Heading1";
import kpy from "./kpy.png";
import Rectangle from "./Rectangle.png";
import error from "./error.png";
import clock from "./clock.png";
import healthy from "./healthy.png";
import advice from "./advice.png";
import { FaRegCopy } from "react-icons/fa";
import disclaimer from './disclaimer.png'
import Modal from "../../component/common/Modal";
import { RxCrossCircled } from "react-icons/rx";
import { useState } from "react";
import useToastWrapper from "../../hooks/useToastWrapper";

function AttentionReport({policy,draftDetails} : any) {
    const [openModal, setOpenModal] = useState(false);
    const openModals = () => setOpenModal(true)
    const { showToast } = useToastWrapper()
    const closeModal = () => setOpenModal(false)
    const handleCopyPlainText = () => {
      const tempElement = document.createElement('div');
      if (draftDetails && draftDetails.body) {
        tempElement.innerHTML = draftDetails.body; 
        const plainText:any = tempElement.textContent;
        navigator.clipboard.writeText(plainText)
          .then(() => {
            showToast('Copied to clipboard', 'success')
            console.log('Text copied to clipboard:', plainText);
          })
          .catch(err => {
            console.error('Unable to copy text to clipboard:', err);
          });
      } else {
        console.error('Draft details or body not found.');
      }
    };
  return (
    <div className="relative">
      <div className="relative h-[150px] overflow-hidden">
        <img src={Rectangle} className={`!w-[100%] absolute z-[1] bg-[#faeee7]`} />
        <div className="absolute flex z-[9] right-0 left-0 p-4 justify-start items-center gap-6">
          <img src={kpy} className="" />
          <div className="flex flex-col justify-start items-start">
            <Heading1 className="font-bold text-md">{policy?.PolicyHolderName || ""}</Heading1>
            <Heading1 className="font-semibold text-sm mt-1">
              {policy?.InsuranceCompanyName || " "}
            </Heading1>
            <Heading1 className="font-normal text-xs mt-1">
              {policy?.PolicyName || ""}
            </Heading1>
          </div>
        </div>
      </div>
      <div className="p-4">
        <Heading1 className="text-[#F78C4F] font-bold uppercase">
          NEEDS ATTENTION
        </Heading1>
        <Heading1 className="font-normal text-xs">
          Inconsistency Found - Your Policy has unresolved issues.
        </Heading1>
      </div>
      <div className="p-4 flex flex-col gap-4 shadow-bottom pb-6">
        {policy?.qualityCheckContent?.length &&  policy?.qualityCheckContent?.map((details: any) => {
          return (
            <div className="border-[1px] rounded-[8px] border-[#FF8B84] p-3 relative">
              <div className="w-[78%]">
                <div className="flex gap-2 items-center">
                  <Heading1 className="text-[14px] font-bold text-sm">
                    {details.Title}
                  </Heading1>
                  <Heading1
                    className={`text-[12px] font-normal text-xs`}
                    style={{ color: `${details.SubTitle =="High Impact"
                    ?"#FF8B84" : details.SubTitle =="Low Impact" ? "#3C976A": "#C56D39"
                  }` }}
                  >
                    {details.SubTitle}
                  </Heading1>
                </div>
                <div className="mt-2">
                  <Heading1 className="text-[13px] w-[85%] font-normal text-xs">
                    {details.Description}
                  </Heading1>
                </div>
              </div>
              <img
                src={details.SubTitle === "High Impact" || details.SubTitle === "Medium Impact" ? error : details.SubTitle === "Low Impact" ? clock : healthy}
                className="absolute top-[20%] right-0 w-[90px]"
              />
            </div>
          );
        })}
      </div>
      <div className="mt-2 p-4">
        <div className="flex gap-2 justify-start items-center">
          <img src={advice} />
          <Heading1 className="font-norma text-sm">
            Protect yourself by notifying your insurance company of any
            discrepancies in your policy.
          </Heading1>
        </div>
        <Heading1 className="font-normal text-sm mt-4">
          Send the accurate information to your insurer using your{" "}
          <strong>Registered Email ID</strong> by copying the mail content from
          below.
        </Heading1>
        <button onClick={openModals} className="mx-auto mt-4 text-sm items-center gap-1 flex p-3 rounded-[8px] border-[1px] border-[#CDD7EC] shadow-button bg-[#F0F4FD] color-[#F0F4FD]">
          <FaRegCopy />
          Copy Mail Content
        </button>
      </div>
      <div className="p-4">
        <div className="relative border-[1px] border-[#FFE5C7] p-2 rounded-[8px] bg-[#FEF2E5]">
        <img src={disclaimer} className="absolute left-2 top-0 w-[40%]" />
          <Heading1 className="font-normal text-sm mt-[30px] p-2">
            Kindly note that correcting inaccurate or missing material
            information related to your policy may result in a change in premium
            or even termination of the policy contract in certain cases.
          </Heading1>
        </div>
      </div>
      <Modal showModal={openModal}>
        <div className="w-[90vw]">
            <div className="flex justify-end w-full" onClick={closeModal}><RxCrossCircled /></div>
            <div className="mt-4 pb-4">
                <Heading1 className="font-normal text-sm">Please copy and paste the text from below to email your insurance company :  {draftDetails?.to} from your register email id.</Heading1>
            </div>
            <div className="bg-[#EBEEF3] h-[50vh] overflow-scroll p-4 rounded-[8px] !text-[14px]" dangerouslySetInnerHTML={{ __html: draftDetails?.bodyHtml }}/>
            <div className="flex justify-center items-center p-3 gap-1" onClick={()=>handleCopyPlainText()}>
                <div className="bg-[#EBEEF3] rounded-[8px] p-2"><FaRegCopy /></div>
                <Heading1 className="font-semibold text-sm">Copy Mail Content</Heading1>
            </div>
        </div>
      </Modal>
    </div>
  )
}

export default AttentionReport
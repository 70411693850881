import Navbar from '../layout/navbar';
import ComponentWrapper from '../pages';
import InsurancePolicyDetails from '../pages/insurancePolicyDetails ';
import LoadingScreen from '../pages/loadingscreen';
import OtpWrapper from '../pages/otp';
import PolicyDetails from '../pages/policyDetails';
import UploadImage from '../pages/policyDetails/UploadImage';
import Questionare from '../pages/questionare/Questionare';
import ResultScreen from '../pages/result';
import TryAgain from '../pages/somthingWrong';
import UserPolicyDetails from '../pages/userdetails/UserPolicyDetails';

interface RouteType {
    path: string;
    element?: React.ReactNode;
    privateRoute: boolean;
    children?: RouteType[];
    index?: any;
  }
  
  const routes: RouteType[] = [
    {
      path: '/',
      privateRoute: true,
      children: [
        {
          index: true,
          path: "/",
          element: <ComponentWrapper><UserPolicyDetails /></ComponentWrapper>,
          privateRoute: true,
        },
        {
          index: true,
          path: "/otp",
          element: <ComponentWrapper><OtpWrapper /></ComponentWrapper>,
          privateRoute: true,
        },
        {
          index: true,
          path: "/question",
          element: <ComponentWrapper><Questionare /></ComponentWrapper>,
          privateRoute: true,
        },
        {
          index: true,
          path: "/policy",
          element: <ComponentWrapper><PolicyDetails /></ComponentWrapper>,
          privateRoute: true,
        },
        {
          index: true,
          path: "/upload",
          element: <ComponentWrapper><UploadImage /></ComponentWrapper>,
          privateRoute: true,
        },
        {
          index: true,
          path: "/loading",
          element: <ComponentWrapper><LoadingScreen /></ComponentWrapper>,
          privateRoute: true,
        },
        {
          index: true,
          path: "/result",
          element: <ComponentWrapper className='!p-0'><ResultScreen /></ComponentWrapper>,
          privateRoute: true,
        },
        {
          index: true,
          path: "/policy-details",
          element: <ComponentWrapper><InsurancePolicyDetails /></ComponentWrapper>,
          privateRoute: true,
        },
        {
          index: true,
          path: "/tryAgain",
          element: <ComponentWrapper><TryAgain /></ComponentWrapper>,
          privateRoute: true,
        }
      ]
    }
  ];
  
const allRoutes: RouteType[] = routes;
export default allRoutes